import React from 'react'
import './CVfill.scss'
import {FaChevronDown} from 'react-icons/fa'
import {HiCursorClick} from 'react-icons/hi'
import {BsGithub} from 'react-icons/bs'
import {FaLinkedinIn} from 'react-icons/fa'

const CVfill = ({CVjson}) => {
    const cv = JSON.parse(CVjson)
    const jobs = cv.jobs.sort((a,b)=>b.id-a.id)
    console.log(jobs);
  return (
    <main>
        <div className="width half">

{/* left */}
            <div className="left">
                <h3 className='sectionName'>Vzdělání</h3>
                <section className='timeLineSection'>
        
                    <article className='paddingB2 timeLineArticle'>
                        <h3 className='pointText'>2006 - Střední škola Vyškov</h3>
                        <h3 className='pointTextUnder'>Obor Pozemní stavitelství</h3>
                    </article>
                    <article className='paddingB2 timeLineArticle'>
                        <h3 className='pointText'>2010 - Jazyková škola Kotlářská</h3>
                        <h3 className='pointTextUnder'>Jazyk: Anglický</h3>
                    </article>
                </section>


                <h3 className='sectionName'>Pracovní historie</h3>

                <section className='timeLineSection'>
                    {jobs.map(x=>
                        <article key={x.id} className="paddingB3 timeLineArticle">
                            <h3 className='pointText'>{x.from} - {x.company}</h3>
                            <h3 className='pointTextUnder'>{x.what}</h3>
                            <p className='jobInfo'>{x.des}</p>
                            <p className='to'>{x.when}</p>
                    </article>
                    )}
                </section>


            </div>
{/* right */}
            <div className="right">

                <h3 className='sectionName'>Programovací dovednosti</h3>
                <section>
                    {cv.Programovaní.map(x=><h3 key={x.id}>{x.name}</h3>)}
                </section>

                <h3 className='sectionName'>Jiné dovednosti</h3>
                <section>
                    {cv.Skills.map(x=>
               
                                <h3 key={x.id}>{x.name}</h3>
                            
                    )}
                </section>

                <h3 className='sectionName'>Kontakt</h3>
                <section>
                    <h3><a href="mailto:PavelGrec73@gmail.com">Email:  PavelGrec73@gmail.com<HiCursorClick className='iconRelative'/></a></h3>
                    <h3><a href="tel:720 642 622">Tel:  720 642 622<HiCursorClick className='iconRelative'/></a></h3>
                    <h3><a href="https://github.com/PavelGrec" target="blank"><BsGithub/>GitHub</a></h3>
                    <h3><a href="https://www.linkedin.com/in/pavel-grec/" target="blank"><FaLinkedinIn/>LinkIn</a></h3><br/>
                    <h3>Bydliště: Nemojany 73, 68303 Luleč</h3>
                    <h3>DOB: 22. 5. 1991...(31 let)</h3>
                </section>


                
            </div>
        </div>
    </main>
  )
}

export default CVfill