import React from 'react'
import Icon from '../components/Icon';
import data from '../components/data';

const Web = () => {

  return (


    <main>
        <div className="width">
            <section id='webHeaderSection'>
            <h2>Web</h2>
            <p className='tc'>Na tvorbu webu používám jazyky HTML, CSS, SCSS, Javascript, React, nějaky zkušenosti mám i s frameworky Vue, Bootstrap</p>
            </section>

            <section id='webSection'>
                <h3>Web</h3>
                <div className="icons">
                {data().webPages.map(x=>{
                    if(x.type==='web'){return<Icon key={x.id} dato={x}/>}
                    })}
                </div>
            </section>

            <section id='appSection'>
                <h3>APP</h3>
                <div className="icons">
                {data().webPages.map(x=>{
                    if(x.type==='app'){return<Icon key={x.id} dato={x}/>}
                    })}
                </div>
            </section>

        </div>

    </main>
  )
}

export default Web