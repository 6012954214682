import './App.css';
import Nav from './components/Nav';

//imponentu
import Intro from './View/Intro';
import Web from './View/Web';
import Grafika from './View/Grafika';
import {Route,Routes} from 'react-router-dom';
import cv from './components/CV';


function App() {
  const data = {
    
  }
let CVjson = JSON.stringify(cv);





  return (
    <>
    <Nav/>
    <Routes>
      <Route path="/" exact={true} element={<Intro CVjson={CVjson}/>}/>
      <Route path="/Web" element={<Web data={data}/>}/>
      {/* <Route path="/Grafika" element={<Grafika />}/> */}
    </Routes>
    
    </>
  );
}

export default App;
