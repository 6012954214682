import {AiFillInfoCircle} from 'react-icons/ai'

const Icon = (dato) => {

  return (
    <a className='icon' href={dato.dato.url} target="blank">
        <img src={dato.dato.img} alt="ikonu aplikace" className='iconIMG'/>

        <p className='iconName'>{dato.dato.name}</p>
    </a>
  )
}

export default Icon