

import React from 'react'

const data = () => {
  return (
    {webPages:[
        {id:1,name:'Shaman',   img:'http://pavelg.cz/img/Shaman.png',des:'Na tuto stránku jsou pužité jazyky java',url:'http://pavelg.cz/project/Shaman',type:'web'},
        {id:2,name:'Reminder', img:'http://pavelg.cz/img/Reminder.png',des:'Pouzity ššš',url:'http://pavelg.cz/app/Remainder', type:'app'},
        {id:3,name:'Kavarna',  img:'http://pavelg.cz/img/Kavarna.png',des:'Pouzity Js',url:'http://pavelg.cz/project/Kavarna/', type:'web'},
        {id:4,name:'AddStory', img:'http://pavelg.cz/img/AddStory.png',des:'Pouzity ššš',url:'http://pavelg.cz/project/AddStory', type:'web'},
        {id:5,name:'Kadeřníctví Brush', img:'http://pavelg.cz/img/Brush.png',des:'Pouzity ššš',url:'http://pavelg.cz/project/Brush', type:'web'},
        {id:6,name:'Calculator', img:'http://pavelg.cz/img/Calculator.png',des:'Pouzity ššš',url:'http://pavelg.cz/app/Calculator/', type:'app'},
        {id:7,name:'Peťánek', img:'http://pavelg.cz/img/Petanek.png',des:'Pouzity ššš',url:'http://pavelg.cz/app/Petanek', type:'app'},
        {id:8,name:'Save URL', img:'http://pavelg.cz/img/SaveUrl.png',des:'Pouzity ššš',url:'http://pavelg.cz/app/SaveUrl', type:'app'},
        {id:9,name:'Restaurace U našich', img:'http://pavelg.cz/img/UNasich.png',des:'Pouzity ššš',url:'http://pavelg.cz/project/Restaurace', type:'web'},
    ]}
  )
}

export default data