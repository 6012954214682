
import React from 'react'

const Header = ({CVjson}) => {
    const CV = JSON.parse(CVjson)
  return (
    <header>
        <div className="headerWidth width">
            <h1>  
              {/* <input id="inputh1" type="range"></input>  */}
              {CV.name}<span>{" G"}</span>
            </h1>
        </div>
    </header>
  )
}

export default Header