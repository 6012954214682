let CV ={
    "name":"Pavel",
    "last":"Grec",
    "age":30,
    "info":{
        "email":"PavelGrec73@gmail.com",
        "DOB":"22.5.1991",
        "phone":"720 642 622",
        "city":"Nemojany 73",
    },
    "infoRun":[
        {
            "id":"1",
            "name":"Email:  PavelGrec73@gmail.com"
        },
        {
            "id":"2",
            "name":"DOB:  22.5.1991"
        },
        {
            "id":"3",
            "name":"Tel:  720 642 622"
        },
        {
            "id":"4",
            "name":" Bydliště:  Nemojany 73"
        }
    ],
    "schools":{
        "elementary":[
            {
                "id":"1",
                "what":"Základní škola Nemojany",
                "when":"1997 - 1999",
                "duration":"2 roky"
            },
            {
                "id":"2",
                "what":"Základní škola Tučapy",
                "when":"1999 - 2002",
                "duration":"3 roky"
            },
            {
                "id":"3",
                "what":"Základní škola Drnovice",
                "when":"2002 - 2006",
                "duration":"4 roky"
            }
            ]
        ,
        "high":[
            {
                "id":"1",
                "what":"SOŠ A SOU škola Sochorova Vyškov",
                "study":"Obor pozemní stavitelství s maturitou",
                "when":"2006 - 2010",
                "duration":"4 roky"
            },
            {
                "id":"2",
                "what":"Jazykova škola Kotlářská",
                "study":"Anglický jazyk",
                "when":"2010 - 2011",
                "duration":"1 rok"
            }
        ],},
    "jobs":[
        {
            "id":"1",
            "what":"Obchodní reprezentant",
            "company":"Zepter",
            "when":"1.2011 - 6.2012",
            "from":"1.2011",
            "to":"6.2012",
            "where":"Brno, skořepka",
            "des":"Oslovování stávajících i potenciálních zákazníkům, prodej kvalitního Švýcarského nádobí.",
            "country":"CZ"
        },
        {
            "id":"2",
            "what":"Kitchen porter",
            "company":"Greens Restaurant",
            "when":"2.2012 - 10.2013",
            "from":"2.2012",
            "to":"10.2013",
            "where":"Manchester, Lapwing ln",
            "des":"Udržování hygienického prostředí ve vegetariánské restauraci. Pomoc při dodávce potravin a pomoc s přípravou zeleniny před vařením.",
            "country":"UK"
        },
        {
            "id":"3",
            "what":"Montážník",
            "company":"Fritzmeier",
            "when":"1.2014 - 9.2014",
            "from":"1.2014",
            "to":"9.2014",
            "where":"Vyškov, Tovární",
            "des":"Srávné zapojení klimatiace do platové střechy a nasazení střechy do kovového skeletu silničního stroje Dynapac",
            "country":"CZ"
        },
        {
            "id":"4",
            "what":"Chef",
            "company":"The Modern Caterer",
            "when":"12.2014 - 12.2015",
            "from":"12.2014",
            "to":"12.2015",
            "where":"Manchester, Oxford Road",
            "des":"Týmová práce zahrnovala, udržování pořádku na pracovišt, přebírání zboží, přípravu pokrmů a příjemné vystupování před hosty",
            "country":"UK"
        },
        {
            "id":"5",
            "what":"Grafik",
            "company":"Elsa’s sports",
            "when":"5.2016 - 9.2016",
            "from":"5. 2016",
            "to":"9. 2010",
            "where":"Rousínov, U Stadionu",
            "des":"Grafické úprava sportovních dresů pro každou velikost dle střihu a zároveň úprava pro jednotlivé hráče.",
            "country":"CZ"
        },
        {
            "id":"6",
            "what":"Stolař",
            "company":"Adi interier,",
            "when":"11.2016 - 9.2017",
            "from":"11. 2016",
            "to":"9. 2017",
            "where":"Brno, Výstaviště",
            "des":"Práce s materiály Compact nebo Lamino. Střídavě pracovní pozice u pily nebo montáž nábytku ve výrobní hale.",
            "country":"CZ"
        },
        {
            "id":"7",
            "what":"CNC operátor",
            "company":"Gravitech",
            "when":"9.2017 - 4.2022",
            "from":"9. 2017",
            "to":"4. 2022",
            "where":"Vyškov, Tovární",
            "des":"Vstupní a výstupní kontrola včetně výroby na vřetenovém CNC stroji. Dovážení materiálu tranzitem a zajištění expedice. Práce s pc a udržování chodu firmy.",
            "country":"CZ"
        },
        {
            "id":"8",
            "what":"CNC programátor",
            "company":"Lazam CZ",
            "when":"8. 2022 - 9. 2022",
            "from":"8. 2022",
            "to":"9. 2022",
            "where":"Drnovice, Kašparov",
            "des":"Vytváření programů pro CNC stroj v programu CADman, vytváření cenové nabídky na základě materiálu a počtu osob a času na dílech strávený, odchod beru jako nešťastné načasování příchodu do firmy, kdy přišel nový systém do firmy protkaný všemi pozicemi a nevnímám to jako selhání",
            "country":"CZ"
        },
    ],
    "Skills":[
        {
            "id":"1",
            "name":"Řidičský průkaz B",
            "level":"4",
        },
        {
            "id":"2",
            "name":"MS ofice",
            "level":"4",
        },
        // {
        //     "id":"3",
        //     "name":"",
        //     "level":"4",
        // },
        {
            "id":"4",
            "name":"Anglický jazyk",
            "level":"4",
        },
        {
            "id":"3",
            "name":"Grafické prohramy",
            "level":"Corel Draw, Canva, Photopea",
        },
        {
            "id":"5",
            "name":"Manuální práce",
            "des":"Jeřáb, šuplera, úchylkometr,elektronický microscop,",
            "level":"4",
        },
        {
            "id":"6",
            "name":"3D programy",
            "des":"Blender, Cinema 4D, 3DS max",
            "level":"5",
        },
    ],
    "Programovaní":[
        {
            "id":"1",
            "name":"HTML",
            "level":"5",
        },
        {
            "id":"2",
            "name":"SCSS",
            "level":"5",
        },
        {
            "id":"3",
            "name":"JavaSript ES 6+",
            "level":"5",
        },
        {
            "id":"4",
            "name":"React",
            "level":"5",
        },
        {
            "id":"5",
            "name":"Vue",
            "level":"5",
        },
        {
            "id":"6",
            "name":"Php",
            "level":"5",
        },
        {
            "id":"7",
            "name":"Node",
            "level":"5",
        }
    ]
}
export default CV 