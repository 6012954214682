import React from 'react'
import { NavLink } from 'react-router-dom'


const Nav = () => {

  return (
    <nav>
        <div className="width navWidth">
            <NavLink to="/">O mě</NavLink>
            <NavLink to="/Web">Web</NavLink>
            {/* <NavLink to="/Grafika">Grafika</NavLink> */}


        </div>
    </nav>
  )
}

export default Nav