import React from 'react'
import Header from '../components/Header'
// import { useState } from 'react'
// import Nav from '../components/Nav'
// import Jobs from '../components/Jobs'
import Edu from '../components/Edu'
import CVfill from '../components/CVfill'
const Intro = ({CVjson}) => {
    const CV = JSON.parse(CVjson)


//states


  return (
    <div>
        <Header CVjson={CVjson}/>
        <main className='paddingB3'>
          <div className="width">
            
          <p>Jmenuji se Pavel Grec. V mládi mě bavily počítačové hry a později jsem začal v ních vytvářet mapy, lehké jednoduché úpravy grafiky a stím jak šel čas mě začala bavit 3D grafika, ale už se tomu moc nevěnuju, ale dokáži ocenit 3D efekty, filmy a hry možná více než jiní. Dnes se věnuji programování i když musím uznat že je to náročnější vlastně po mnoha směrech. Rád bych získal i zaměstnání v tomto oboru. Mezi jazyky jsou poslední dobou jsou moje jazyky Scss a React i když jsem možná větší fanda Vue, Do budoucna bych chtěl rozvinout back-End v node JS a navázání do databáze a naučit se tailwind. Stránky na tomto webu vznikly i v dobách když jsem Neuměl ES6 a proto ne vždy mohou být uplně prezenční, ale tak jde prostě čas</p>
            </div>
        </main>
        <CVfill CVjson={CVjson}/>
    </div>
  )
}

export default Intro